import { Component, inject, ViewEncapsulation, OnInit } from '@angular/core';
import { FormBuilder, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { languages } from '../../../core/enums';
import { toArray } from 'lodash-es';
import { LanguageService } from '../../../core/services/language.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { ImgComponent, SelectComponent } from '@assecods/design-system-lib';

@Component({
  selector: 'bz-language',
  templateUrl: './language.component.html',
  styleUrl: './language.component.scss',
  providers: [FormGroupDirective],
  imports: [SelectComponent, ReactiveFormsModule, ImgComponent],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
})
export class LanguageComponent implements OnInit {
  private readonly fb = inject(FormBuilder);
  private readonly translate = inject(TranslateService);
  private readonly languageService = inject(LanguageService);

  form = this.fb.group({
    lang: 'pl',
  });
  items: { key: string; value: string }[] = [];

  get lang() {
    return this.form.get('lang');
  }

  ngOnInit() {
    this.translate.onLangChange.pipe(distinctUntilChanged()).subscribe(() => this.setLanguages());
  }

  changeLanguage(lang: string) {
    this.languageService.setLanguage(lang);
  }

  private setLanguages() {
    this.items = [];

    toArray(languages)
      .filter((lang) => lang === lang.toLowerCase())
      .forEach((value) => {
        this.items.push({
          key: this.translate.instant(`common.languages.${value}`),
          value,
        });
      });
  }
}
