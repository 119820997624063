import { EventEmitter, inject, Injectable, WritableSignal } from '@angular/core';

import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { languages } from '../enums';
import { map, startWith, switchMap } from 'rxjs/operators';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private readonly translate = inject(TranslateService);

  /**
   * Get current language
   * @return current user language
   */
  getLanguage(): string {
    return this.getCurrentTranslateLanguage();
  }

  /**
   * Used in getLanguage()
   */
  getCurrentTranslateLanguage() {
    return this.translate.getDefaultLang();
  }

  /**
   * Set language
   * @param lang
   */
  setLanguage(lang: any = languages.pl) {
    this.translate.use(lang);
  }

  instant(key: WritableSignal<string>) {
    return toSignal(
      toObservable(key).pipe(
        switchMap((key: string) => {
          return this.translate.onLangChange.pipe(
            startWith({} as EventEmitter<LangChangeEvent>),
            switchMap(() => this.translate.get(key)),
            map((x: string) => x)
          );
        })
      )
    );
  }
}
