import { Component, inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { LanguageService } from './core/services/language.service';
import { AuthService } from './core/services/auth.service';
import { ImgComponent, ToastComponent, ToastIcon, ToastType } from '@assecods/design-system-lib';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RouterOutlet } from '@angular/router';
import { AsyncPipe } from '@angular/common';
import { DurationPipe } from './shared/pipes/duration.pipe';
import { LanguageComponent } from './shared/components/language/language.component';

@Component({
  selector: 'bz-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    ToastComponent,
    LanguageComponent,
    RouterOutlet,
    AsyncPipe,
    DurationPipe,
    TranslateModule,
    ImgComponent,
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  isMobile!: boolean;
  readonly toastType = ToastType;
  readonly toastIcon = ToastIcon;
  private readonly subscription = new Subscription();

  private readonly language = inject(LanguageService);
  private readonly authService = inject(AuthService);
  private readonly deviceDetector = inject(DeviceDetectorService);
  private readonly translate = inject(TranslateService);

  timeToExpiration$ = this.authService.timeToExpiration$;

  get expireWarningKey() {
    return this.authService.sessionExpireMessageKey;
  }

  constructor() {
    this.translate.setDefaultLang('pl');
  }

  ngOnInit() {
    this.language.setLanguage();
    this.isMobile = this.deviceDetector.isMobile(navigator.userAgent);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
